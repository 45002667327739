import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import DotLoader from '@web/components/DotLoader';
import InfoBox from '../InfoBox';
import PartyIcon from './PartyIcon';

import { callComplete, machineTransition } from '@web/reducers/dialerReducer';
import useQueueCall from '../CallStatus/components/VolunteerIdle/useQueueCall';
import {
  SESSION_STATS_VIEWED,
  SESSION_STATS_VIEWED_AUTO_CONNECT,
} from '@web/reducers/dialerReducer/dialerMachine';
import { useDispatch, useSelector } from 'react-redux';

function SessionStats({ activity }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  // We add a +1 here because we increment the totalCalls property as
  // part of the callComplete action.
  const volunteerTotal = useSelector(state => state.dialer.totalCalls + 1);
  const [phoneBankTotal, setPhoneBankTotal] = React.useState(volunteerTotal);

  const { queueCallIfConnected } = useQueueCall(activity?.id);

  React.useEffect(() => {
    const url = `/api/v2/client/activities/${activity.id}/voice_call_pool/metrics`;
    axios(url)
      .then(({ data }) => {
        setPhoneBankTotal(data?.voter_calls_attempted_count || volunteerTotal);
      })
      .catch(() => {
        // MTS - We don't need to do anything here
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  function handleClick() {
    dispatch(callComplete());

    // We have to simulate the "Queue Next Call" click in case dialer_auto_queue is true
    if (activity?.campaign?.dialer_auto_queue_enabled) {
      dispatch(machineTransition(SESSION_STATS_VIEWED_AUTO_CONNECT));
      queueCallIfConnected();
    } else {
      dispatch(machineTransition(SESSION_STATS_VIEWED));
    }
  }

  const buttonProps = {
    children: activity?.campaign?.dialer_auto_queue_enabled ? 'Next Call' : 'Continue',
    loading,
    onClick: handleClick,
  };

  const totalPlural = phoneBankTotal !== 1;
  const volunteerPlural = volunteerTotal !== 1;

  if (loading) {
    return <DotLoader centered color="teal" />;
  }

  const text = `
  You've connected to ${volunteerTotal} call${
    volunteerPlural ? 's' : ''
  } this session. Together, we've made ${phoneBankTotal} call${
    totalPlural ? 's' : ''
  } total so far this phone bank!
  `;

  return (
    <InfoBox
      buttonProps={buttonProps}
      icon={<PartyIcon height={78} style={{ marginBottom: 14 }} />}
      text={text}
      title="Way to go!"
    />
  );
}

SessionStats.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      dialer_auto_queue_enabled: PropTypes.bool,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
};

SessionStats.defaultProps = {};

export default SessionStats;
