import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

const CloseButton = styled.button`
  border: 0;
  background-color: transparent;
  color: black;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
`;

const Details = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Detail = styled.li`
  color: black;
  font-size: 16px;
  margin-bottom: 4px;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 24px;
  letter-spacing: 0.94px;
  margin-bottom: 0;
`;

const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 40px 13px;
  width: 100%;
  align-items: flex-start;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

function Contact({ activity }) {
  const [detailsOpen, setDetailsOpen] = React.useState(true);
  const { connectedContact } = useSelector(state => state.dialer);

  function renderDetail(el) {
    const label = activity?.localized_presented_contact_details[el];
    let text = '';
    try {
      text = connectedContact[el];
    } catch (e) {}
    if (typeof text === 'boolean') {
      text = text.toString();
    }
    if (text) {
      return (
        <Detail key={el}>
          {label}: {text}
        </Detail>
      );
    }
    return null;
  }

  if (detailsOpen) {
    return (
      <StyledContact>
        <Top>
          <Title>
            {connectedContact?.first_name} {connectedContact?.last_name}
          </Title>
          <Details style={{ marginTop: 8 }}>
            <Detail>
              {connectedContact?.state_abbrev || 'Unknown State'} |{' '}
              {connectedContact?.party_affiliation || 'Unknown Party Affiliation'}
            </Detail>
            {connectedContact && activity?.presented_contact_details.map(renderDetail)}
          </Details>
          <CloseButton onClick={() => setDetailsOpen(false)}>Hide details</CloseButton>
        </Top>
      </StyledContact>
    );
  }

  return (
    <StyledContact>
      <Top>
        <Title>
          {connectedContact?.first_name} {connectedContact?.last_name}
        </Title>
      </Top>
      <CloseButton onClick={() => setDetailsOpen(true)}>Show details</CloseButton>
    </StyledContact>
  );
}

Contact.propTypes = {
  activity: PropTypes.object.isRequired,
};

Contact.defaultProps = {};

export default Contact;
