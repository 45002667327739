import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import Button from '@web/components/DialerActivity/components/Button';
import Elapsed from './Elapsed';
import StatusBar from '../StatusBar';
import StatusBarButton from '../StatusBarButton';

import { END_SESSION } from '@web/reducers/dialerReducer/dialerMachine';
import { machineTransition } from '@web/reducers/dialerReducer';
import sendError from '@web/utils/sendError';
import { useDispatch, useSelector } from 'react-redux';
import useQueueCall from './useQueueCall';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 32px 30px;
`;

const BannerText = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

function VolunteerIdle({ activity, content }) {
  const dispatch = useDispatch();
  const { dialerLoading } = useSelector(state => state.dialer);
  const { loading, queueCallIfConnected, setLoading } = useQueueCall(activity?.id);

  function handleEndSession() {
    setLoading(true);
    const url = `/api/v2/activities/${activity?.id}/volunteer_voice_call`;
    axios
      .delete(url)
      .then(res => {})
      .catch(err => {
        sendError('Error ending session via end session button', {
          request: err?.request,
          response: err?.response,
        });
      })
      .finally(() => {
        setLoading(false);
        dispatch(machineTransition(END_SESSION));
      });
  }

  const isLoading = React.useMemo(() => dialerLoading || loading, [dialerLoading, loading]);
  const hasContent = !!content;
  return (
    <>
      <StatusBar bgColor="darkBlue">
        <BannerText color={'white'}>
          {'Your Session'}
          <Elapsed />
        </BannerText>
        <StatusBarButton
          bgColor="darkBlue"
          disabled={loading}
          loading={loading}
          onClick={handleEndSession}
          style={{ padding: '0 10px' }}
        >
          End Session
        </StatusBarButton>
      </StatusBar>
      <Content>
        {hasContent && content}
        {!hasContent && (
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={queueCallIfConnected}
            variant="teal"
          >
            Queue Next Call
          </Button>
        )}
      </Content>
    </>
  );
}

VolunteerIdle.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  content: PropTypes.any,
};

VolunteerIdle.defaultProps = {
  content: null,
};

export default VolunteerIdle;
