/* eslint-disable sort-keys */
export const plans = [
  {
    name: 'Standard',
    slug: 'standard',
    price: '50',
    description:
      'Best for small, self-sufficient campaigns and organizations; includes our best-in-class relational organizing suite and any outreach tools.',
    activityPrices: {
      p2pSms: 0.025,
      p2pMms: 0.05,
      broadcastSms: 0.025,
      broadcastMms: 0.05,
      phoneBanking: 0.05,
      voterRegistration: 0.08,
    },
    options: {
      'Texting & Calling': {
        'Peer-to-Peer Texting': true,
        'Broadcast Texting': true,
        'Phone Banking': true,
      },
      'Form-Based Engagement': {
        'Voter Registration':
          '<strong>1,000 Total Unique Responses Included Per Month</strong><span>+8¢ per additional response</span>',
        'Custom Forms': '',
      },
      'Relational Tools': {
        'Friends and Family Messaging': true,
        'Social Media Amplification': true,
        'Digital Canvassing (Add Report)': true,
        'Shared Canvassing & List Canvassing': false,
        'Suggested Contacts': false,
      },
      'Program Management': {
        'Teams & Leaderboards': true,
        'Team Chat': false,
        'Advanced Form Customizations': false,
      },
      Data: {
        'Customizable Data Exports': true,
        'CRM Sync (NGP VAN, PDI, Salsa Labs, etc.)': true,
        'Full-Service Data Integration': false,
      },
      'Client Support and Strategy': {
        'Onboarding Materials': true,
        'Helpdesk and Support Ticketing': true,
        'Weekly Training Sessions & Office Hours': false,
        'Dedicated Client Strategy Advisor': false,
      },
    },
  },
  {
    name: 'Professional',
    slug: 'professional',
    price: '500',
    description:
      'Best for medium-sized campaigns and organizations that need weekly support and training from our Client Strategy team.',
    activityPrices: {
      p2pSms: 0.025,
      p2pMms: 0.05,
      broadcastSms: 0.025,
      broadcastMms: 0.05,
      phoneBanking: 0.05,
      voterRegistration: 0.08,
    },
    options: {
      'Texting & Calling': {
        'Peer-to-Peer Texting': true,
        'Broadcast Texting': true,
        'Phone Banking': true,
      },
      'Form-Based Engagement': {
        'Voter Registration':
          '<strong>5,000 Total Unique Responses Included Per Month</strong><span>+8¢ per additional response</span>',
        'Custom Forms': '',
      },
      'Relational Tools': {
        'Friends and Family Messaging': true,
        'Social Media Amplification': true,
        'Digital Canvassing (Add Report)': true,
        'Shared Canvassing & List Canvassing': true,
        'Suggested Contacts': true,
      },
      'Program Management': {
        'Teams & Leaderboards': true,
        'Team Chat': true,
        'Advanced Form Customizations': true,
      },
      Data: {
        'Customizable Data Exports': true,
        'CRM Sync (NGP VAN, PDI, Salsa Labs, etc.)': true,
        'Full-Service Data Integration': false,
      },
      'Client Support and Strategy': {
        'Onboarding Materials': true,
        'Helpdesk and Support Ticketing': true,
        'Weekly Training Sessions & Office Hours': true,
        'Dedicated Client Strategy Advisor': false,
      },
    },
  },
  {
    name: 'Enterprise',
    slug: 'enterprise',
    price: '1000',
    description:
      'Best for large campaigns and organizations that need personalized support from our Client Strategy team, including custom data integration and strategic assistance.',
    activityPrices: {
      p2pSms: 0.025,
      p2pMms: 0.05,
      broadcastSms: 0.025,
      broadcastMms: 0.05,
      phoneBanking: 0.05,
      voterRegistration: 0.08,
    },
    startingAt: true, // will show as "Starting at $1000" in the plan selector, instead of just "$1000"
    options: {
      'Texting & Calling': {
        'Peer-to-Peer Texting': true,
        'Broadcast Texting': true,
        'Phone Banking': true,
      },
      'Form-Based Engagement': {
        'Voter Registration':
          '<strong>10,000 Total Unique Responses Included Per Month</strong><span>+8¢ per additional response</span>',
        'Custom Forms': '',
      },
      'Relational Tools': {
        'Friends and Family Messaging': true,
        'Social Media Amplification': true,
        'Digital Canvassing (Add Report)': true,
        'Shared Canvassing & List Canvassing': true,
        'Suggested Contacts': true,
      },
      'Program Management': {
        'Teams & Leaderboards': true,
        'Team Chat': true,
        'Advanced Form Customizations': true,
      },
      Data: {
        'Customizable Data Exports': true,
        'CRM Sync (NGP VAN, PDI, Salsa Labs, etc.)': true,
        'Full-Service Data Integration': true,
      },
      'Client Support and Strategy': {
        'Onboarding Materials': true,
        'Helpdesk and Support Ticketing': true,
        'Weekly Training Sessions & Office Hours': true,
        'Dedicated Client Strategy Advisor': true,
      },
    },
  },
];
